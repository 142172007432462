import finInsights from './api';
export const generateMagicLink = (email, callback) => loginWithEmail(email)
    .catch((error) => console.log({ error }))
    .finally(() => callback());
export const loginWithEmail = (email) => finInsights.post('login', { email });
export const getCustomers = (currentPage) => finInsights.get('/customers');
export const updateCustomerStatus = (cutomer_id, status) => finInsights.put(`/customers/${cutomer_id}`, {
    status: status,
});
export const getOverview = () => finInsights.get('/overview');
export const getCustomerDetails = (sme_tenant_id, sme_identity_id) => finInsights.get(`/customers/${sme_tenant_id}/insights?identity_id=${sme_identity_id}`);
export const getRecommendations = (sme_tenant_id, sme_identity_id) => finInsights.get(`/customers/recommendations/${sme_tenant_id}?identity_id=${sme_identity_id}`);
export const inviteUser = (payload) => finInsights.post(`/invitations`, payload);
export const getPendingInvitationsCustomers = () => finInsights.get(`/customers?${['INVITED', 'ENROLLED'].map((n) => `invitation_status_in=${n}`).join('&')}`);

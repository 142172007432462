import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { t } from 'i18next';
import CustomDropDown from 'ui-components-web/src/Components/CustomDropDown';
import { ActionOptions } from 'Global/types';
import { defaultSort, renderPendingInvitationStatus, renderSuggestedAction } from 'Global/utils';
import { updateCustomerStatus } from 'Service/api/finInsights';
import { ListContainer, TextRowContent } from './style';
export const Sorter = {
    DEFAULT: defaultSort,
};
const handleUpdateStatus = (customer_id, value) => updateCustomerStatus(customer_id, value)
    .then((result) => console.log({ result }))
    .catch((error) => console.log({ error }));
const renderPendingInvitationsColumns = () => [
    {
        title: t('CustomersTable.Customers'),
        dataIndex: 'company_name',
        key: 'company_name',
        render: (text, record) => {
            const { owner_name, account_number } = record;
            return (_jsxs("ul", { style: { listStyleType: 'none', padding: 0 }, children: [_jsx(TextRowContent, { children: text }, text), _jsx(TextRowContent, { "$isGray": true, children: owner_name }, owner_name), _jsxs(TextRowContent, { "$isGray": true, children: ["AN - ", account_number] }, account_number)] }));
        },
        sorter: {
            compare: Sorter.DEFAULT,
            multiple: 3,
        },
    },
    {
        title: 'Einladungsstatus',
        dataIndex: 'invitation_status',
        key: 'invitation_status',
        render: (status, record) => {
            const { invitation_status } = record;
            return (_jsx(ListContainer, { children: _jsx(TextRowContent, { children: invitation_status && renderPendingInvitationStatus(invitation_status) }, status) }));
        },
    },
    {
        title: t('CustomersTable.RecommendedAction'),
        key: 'recommended_action',
        dataIndex: 'recommended_action',
        render: (text, record) => {
            const { recommended_action_date } = record;
            return (_jsxs(ListContainer, { children: [_jsx(TextRowContent, { children: renderSuggestedAction(text) }, text), _jsx(TextRowContent, { "$isGray": true, children: recommended_action_date && recommended_action_date.split('T')[0] }, recommended_action_date)] }));
        },
    },
    {
        title: t('CustomersTable.status'),
        key: 'action',
        render: (text, record) => {
            const { last_action_taken } = record;
            return (_jsx("div", { "data-test-id": 'action-drop-down', style: { width: '10em' }, onClick: (e) => e.stopPropagation(), children: _jsx(CustomDropDown, { width: '100%', defaultValue: last_action_taken, options: ActionOptions, placeholder: 'Aktion', callback: (value) => record?.sme_tenant_id && handleUpdateStatus(record?.sme_tenant_id, value.value) }) }));
        },
    },
];
export default renderPendingInvitationsColumns;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Col } from 'antd';
import { t } from 'i18next';
import { RowBlockWithTag } from 'ui-components-web';
import { v4 as uuidv4 } from 'uuid';
import { CustomEvents } from 'Global/types';
import { trackEvent } from 'Global/utils';
function RecomendationsList({ items }) {
    const handleClick = () => {
        trackEvent(CustomEvents.PRODUCT_RECOMENDATION_ROW_CLICK);
    };
    const renderRow = () => items.map((row) => (_jsx(Col, { style: { paddingBlock: '0.5em' }, children: _jsx(RowBlockWithTag, { product: t(`ProductRecommendations.${row.product}.title`), product_type: t(`ProductRecommendations.${row.product}.sub_category`), rating: row.affinity_score + '%' }) }, uuidv4())));
    return (_jsxs("div", { children: [_jsx("h2", { style: { marginTop: '0px', paddingBottom: '2px' }, children: t('CustomerDetails.ProductRecommendation') }), _jsx("div", { style: { height: '100%' }, children: renderRow() })] }));
}
export default RecomendationsList;

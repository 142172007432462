import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { t } from 'i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, CustomList, HorizontalMenu, SearchInput, } from 'ui-components-web';
import InfoBoxCounter from 'ui-components-web/src/Components/InfoBox';
import FincheckLogo from 'Assets/Fincheck-logo.svg';
import UserChecked from 'Assets/User-checked.svg';
import renderFinCheckUsersColumns from 'Components/TableRow/renderFinCheckUsersColumns';
import renderPendingInvitationsColumns from 'Components/TableRow/renderPendingInvitationsColumns';
import { CustomEvents, StorageKeys } from 'Global/types';
import { getItemFromStorage, setItemInStorage, trackEvent } from 'Global/utils';
import ROUTES from 'Router/routes';
import { getCustomers, getOverview, getPendingInvitationsCustomers } from 'Service/api/finInsights';
import { InfoBoxesContainer, ListContainer, SearchContainer } from './style';
const items = [
    {
        label: 'FinCheck Nutzer',
        key: 'fincheckusers',
    },
    {
        label: 'Ausstehende Einladungen',
        key: 'pendingInvitations',
    },
];
function Overview() {
    const navigate = useNavigate();
    const currentPage = useRef(1);
    const vrsg_session = getItemFromStorage(StorageKeys.VRSG_SESSION);
    const [customersList, setCustomersList] = useState([]);
    const [filteredData, setFilteredData] = useState();
    const [pendingInvitations, setPendingInvitations] = useState([]);
    const [isFincheckUsersVisible, setIsFincheckUsersVisible] = useState(true);
    const [overviewValues, setOverviewValues] = useState({
        fincheck_users: 0,
        customers_ready_to_be_contacted: 0,
    });
    const fetchData = useCallback(async () => {
        if (currentPage.current === null)
            return;
        try {
            const response = await getCustomers(currentPage.current);
            const { data } = response;
            currentPage.current = data.next_page;
            if (data.data.length > 0) {
                setCustomersList((prevData) => [...prevData, ...data.data]);
            }
        }
        catch (error) {
            console.error('Failed to fetch data:', error);
        }
    }, []);
    const handleNavigate = () => {
        trackEvent(CustomEvents.INVITE_PORTAL_BTN_CLICK);
        navigate(ROUTES.INVITE_PORTAL);
    };
    const handleNavigateRow = (sme_tenant_id, sme_identity_id) => {
        navigate(ROUTES.CUSTOMER_DETAILS + '/' + sme_tenant_id + '/' + sme_identity_id);
    };
    const handleSearch = (searchObject) => {
        trackEvent(CustomEvents.SEARCH_COMPANY_IN_LIST_INPUT);
        const { value } = searchObject.target;
        const filtered = (customersList || []).filter((item) => Object.keys(item).some((key) => String(item.company_name).toLowerCase().includes(value.toLowerCase())));
        setFilteredData(filtered);
    };
    const handleMenuClick = (e) => {
        if (e === 'pendingInvitations')
            getPendingInvitationsCustomers()
                .then((result) => {
                const { data } = result;
                data.data && setPendingInvitations(data.data);
            })
                .catch((error) => console.log({ error }));
        else
            fetchData();
        setIsFincheckUsersVisible((prevState) => !prevState);
    };
    // Extract token from magic link redirect URL
    useEffect(() => {
        const s = window.location.search;
        if (s) {
            setItemInStorage(StorageKeys.VRSG_SESSION, s.split('s=')[1]);
        }
    }, [vrsg_session]);
    const handleFilter = (filter) => {
        const { value } = filter;
        const filtered = (customersList || []).filter((item) => Object.keys(item).some((key) => {
            if (value !== 'All customers') {
                return String(item.fincheck_status).toLowerCase().includes(value.toLowerCase());
            }
            else
                return item;
        }));
        setFilteredData(filtered);
    };
    const handleScroll = useCallback(() => {
        const scrollPosition = window.innerHeight + document.documentElement.scrollTop;
        const bottomPosition = document.documentElement.offsetHeight;
        if (scrollPosition >= bottomPosition && currentPage.current !== null) {
            fetchData();
        }
    }, []);
    useEffect(() => {
        fetchData();
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    useEffect(() => {
        getOverview()
            .then((result) => {
            const { data } = result;
            setOverviewValues({
                fincheck_users: data.fincheck_users,
                customers_ready_to_be_contacted: data.customers_ready_to_be_contacted,
            });
        })
            .catch((error) => console.log({ error }));
    }, []);
    useEffect(() => {
        setFilteredData(customersList);
    }, [customersList]);
    return (_jsxs("div", { children: [_jsxs("div", { style: { paddingBottom: '5em' }, children: [_jsx("h3", { children: t('OverviewPage.Overview') }), _jsxs(InfoBoxesContainer, { children: [_jsx(InfoBoxCounter, { count: overviewValues.fincheck_users, title: t('OverviewPage.FincheckUsers'), icon: FincheckLogo }), _jsx("div", { style: { position: 'relative', left: '26px' }, children: _jsx(InfoBoxCounter, { count: overviewValues.customers_ready_to_be_contacted, title: t('OverviewPage.AwaitingCustomers'), icon: UserChecked }) })] })] }), _jsxs(ListContainer, { children: [_jsxs("div", { style: {
                            position: 'relative',
                        }, children: [_jsx(HorizontalMenu, { items: items, callback: handleMenuClick }), _jsxs(SearchContainer, { children: [_jsx("div", { style: { paddingRight: '1em' }, children: _jsx(Button, { "data-test-id": 'invite-portal-button', text: t('OverviewPage.InviteCustomers'), type: 'primary', onClick: handleNavigate }) }), _jsx(SearchInput, { placeholder: t('FormsInputs.Search'), onSearch: (text) => handleSearch(text) })] })] }), _jsx(CustomList, { onRowClick: (event, record) => handleNavigateRow(record.sme_tenant_id, record.sme_identity_id), data: isFincheckUsersVisible ? filteredData : pendingInvitations, title: '', columns: isFincheckUsersVisible
                            ? renderFinCheckUsersColumns()
                            : renderPendingInvitationsColumns(), sorterText: t('OverviewPage.SortText'), tableHead: _jsx(_Fragment, {}) })] })] }));
}
export default Overview;

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import PageViewTracker from 'Components/PageViewTracked';
import { getCurrentBase } from 'Global/utils';
import Login from 'Pages/Login';
import ProtectedRouter from './protectedRouter';
const MainAppRouter = () => {
    return (_jsx(_Fragment, { children: _jsxs(BrowserRouter, { basename: getCurrentBase(), children: [_jsx(PageViewTracker, {}), _jsxs(Routes, { children: [_jsx(Route, { path: '/', element: _jsx(Navigate, { to: '/login' }) }), _jsx(Route, { path: '/login', element: _jsx(Login, {}) }), _jsx(Route, { path: '/*', element: _jsx(ProtectedRouter, {}) })] })] }) }));
};
export default MainAppRouter;
